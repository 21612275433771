

























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { NoticeController } from '@/services/request.service'
import { createPagination } from '@/constant/constant'
import filterGroup from '@/components/filterGroup'
import moment from 'moment'
import debounce from 'lodash/debounce'

@Component({
  components: {
    filterGroup,
  },
})
export default class Announcement extends Vue {
  private filters: any = {
    title: '',
    department: 0,
    date: null,
    noticeTagId: 0,
    pageCurrent: 1,
    pageSize: 30,
    clearTags: true,
  }

  // 部门下拉框列表
  private departmentList: Array<any> = []
  // 标签下拉框列表
  private tagList: Array<any> = []
  // 公告列表数据
  private data: any = {}
  // 加载
  private loading: boolean = false
  private total: number = 0

  private get isChildRoute(): boolean {
    return this.$route.name !== 'announcement'
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  private moment = moment

  private created(): void {
    this.getDepartmentList()
    this.getTagList()
    this.getData()
  }

  // 获取部门列表
  private getDepartmentList(): void {
    NoticeController.departsDropDown()
      .then(res => {
        this.departmentList = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  // 获取标签列表
  private getTagList(): void {
    NoticeController.tagDropdown()
      .then(res => {
        this.tagList = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  // 获取数据
  private getData(): void {
    this.loading = false
    this.data = []
    let condition = {
      pageCurrent: this.filters.pageCurrent,
      pageSize: this.filters.pageSize,
      keyword: this.filters.title === '' ? undefined : this.filters.title.trim(),
      department: this.filters.department ? this.filters.department : undefined,
      date: this.filters.date ? this.filters.date.valueOf() : undefined,
      noticeTagId: this.filters.noticeTagId ? this.filters.noticeTagId : undefined,
      studentId: this.studentId,
      clearTags: true,
    }
    this.loading = true
    NoticeController.getNoticePersonal(condition)
      .then(res => {
        this.data = res.data.items
        this.total = res.data.totalItem
        if (this.data) {
          this.data.forEach(item => {
            if (item.noticeTags.length) {
              item.noticeTags.forEach(tag => {
                const rgb: any = []
                const temp = tag.extraValue.split('#')[1]
                for (let i = 0; i < 3; i++) {
                  rgb.push(parseInt(temp.slice(i * 2, i * 2 + 2), 16))
                }
                tag.borderColor = 'rgba(' + rgb.join(',') + ',1)'
                tag.backgroundColor = 'rgba(' + rgb.join(',') + ',0.25)'
              })
            }
          })
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private viewDetail(id): void {
    NoticeController.setRead(id)
    this.$router.push({ name: 'announcementDetail', params: { id: id } })
  }

  private pageChange(page): void {
    this.filters.pageCurrent = page
    this.getData()
  }

  private pageSizeChange(current, size): void {
    this.filters.pageCurrent = current
    this.filters.pageSize = size
    this.getData()
  }

  private reset(): void {
    this.filters = {
      title: '',
      department: 0,
      date: null,
      noticeTagId: 0,
      studentId: this.studentId,
      pageCurrent: 1,
      pageSize: 30,
      clearTags: true,
    }
    this.filters.currentPage = 1
    this.getData()
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  @Watch('studentId', { immediate: true })
  public onStudentChange(newVal): void {
    if (newVal) {
      this.getData()
    }
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(): void {
    this.getData()
  }
}
