import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
@Component({})
export default class filterGroup extends Vue {
  @Prop({ type: Boolean }) private readonly showFold!: any

  private fold = false

  private get realShowFold(): any {
    return this.showFold === '' || this.showFold
  }

  private switchFold(): void {
    this.fold = !this.fold
    this.$emit('changeFold', this.fold)
  }

  public render() {
    let children: any = this.$slots.default || []
    children = children.slice(0, this.fold ? 2 : children.length)
    children = children.filter(item => item.data)
    let rowNum = Math.ceil(children.length / 3)
    if (children.length % 3 === 0) rowNum += 1
    const rowChildren = Array.from({ length: rowNum }).map((n, i) => {
      let tmpArray = [] as any
      for (let j = 0; j < 3; j++) {
        if (j + i * 3 + 1 > children.length) break
        tmpArray.push(children[i * 3 + j])
      }
      return tmpArray
    })
    const btnGroup = this.$slots.btnGroup || []
    const num = 2 - (children.length % 3)
    return (
      <div style="width: 100%">
        {rowChildren.map((row, i) => (
          <a-row class="filter-group" gutter={[48, 0]} key={'r' + i}>
            {row.map((child, j) => (
              <a-col key={'c' + i + j} span={8}>
                {child}
              </a-col>
            ))}
            {i + 1 === rowNum
              ? Array.from({ length: num }).map((n, i) => <a-col key={'b' + i} span={8}></a-col>)
              : undefined}
            {btnGroup.length && i + 1 === rowNum ? (
              <a-col span={8}>
                <div class="filter-btn-group">
                  {btnGroup[0]}
                  {this.realShowFold ? (
                    <div onClick={() => this.switchFold()} class="filter-fold-block">
                      <span>{this.$t(`common.${!this.fold ? '' : 'un'}fold`)}</span>
                      {this.fold ? <a-icon type="down" /> : <a-icon type="up" />}
                    </div>
                  ) : (
                    undefined
                  )}
                </div>
              </a-col>
            ) : (
              undefined
            )}
          </a-row>
        ))}
      </div>
    )
  }
}
